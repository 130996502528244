html {
  scroll-behavior: smooth;
  cursor: default;
}

.ItServiceRow {
  display: flex !important;
  flex-wrap: wrap !important;
}

.ItService{
  display: flex !important;
  padding: 25px 10px !important;
  flex-direction: column !important;
}

.ree-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%; /* Ensure cards are the same height */
}

.ree-card-content {
  flex-grow: 1;
}




.BlogRow {
  display: flex;
  flex-wrap: wrap;
}

.BlogCol4 {
  display: flex;
  flex-direction: column;
}

.half-blog-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%; /* Ensures all cards have the same height */
}

.half-blog-content {
  flex-grow: 1;
}