body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Overlay for background */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  visibility: hidden;
  transition: visibility 0s 0.5s; /* Hide overlay when the nav closes */
}

/* Show overlay when nav is open */
.overlay.open {
  visibility: visible;
  transition: visibility 0s 0s; /* Make overlay visible immediately */
}

/* Nav styles */
#hc-nav-1 {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 300px; /* Adjust width as needed */
  background-color: #fff; /* Adjust color as needed */
  visibility: hidden;
  transform: translateX(-100%);
  transition: transform 0.5s ease-in-out, visibility 0s 0.5s; /* Delay visibility */
}

#hc-nav-1.open {
  visibility: visible;
  transform: translateX(0);
  transition: transform 0.5s ease-in-out;
}

